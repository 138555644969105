/* eslint-disable */
<template>
    <div class="multi-table">
        <b-table
            striped
            hover
            borderless
            fixed
            :items="computed_lines"
            :fields="computed_fields"
            @row-clicked="row_clicked"
            :tbody-transition-props="tbody_transition_props"
            :tbody-tr-class="rowClass"
            :sort-by="sort_by"
            :sort-desc="sort_desc"
			no-sort-reset
			no-local-sorting
			no-select-on-click
        >
            <template slot="thead-top">
                <tr @click="active_head_line($event)">
                    <th class="td_checkbox">
                        <div v-if="has_checkboxes" class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" v-model="computed_head.isActive">
                            <label class="custom-control-label"></label>
                        </div>
                    </th>
                    <th :colspan="computed_colspan" @click="active_head_line($event)">
                        {{ formatHeader(computed_head['[group_by]']) }} 
                    </th>
                    <th v-if="computed_switch">
                        <div class="custom-control custom-checkbox">
                            <b-form-checkbox name="check-button" switch v-model="switch_header" @change="getSwitched(computed_head['[group_by]'], $event)">
                                <template v-if="switch_header">
                                    {{ switchOn }}
                                </template>
                                <template v-else>
                                    {{ switchOff }}
                                </template>
                            </b-form-checkbox>
                        </div>
                    </th>
                </tr>
            </template>

            <!-- Le modèle de checkbox pour les lignes data.rowSelected -->
            <template v-slot:cell([checkbox])="data">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" v-model="data.item.isActive">
                    <label class="custom-control-label"></label>
                </div>
            </template>

			<template v-slot:cell([fake_checkbox])>
				<div class="custom-control custom-checkbox"></div>
            </template>

            <!-- La colonne de prévisualisation par défaut -->
            <template v-slot:cell([preview])>
                <div class="custom-control" :class="[previewColClass, previewColClass+'__default']">
                    <font-awesome-icon :class="[previewColClass, previewColClass+'__default']" :icon="['fas', 'eye']" />
                </div>
            </template>

            <!-- switch (exemple: triggers actifs/inactifs) -->
            <template v-slot:cell([switch])="data">
                <div class="custom-control custom-checkbox">
                    <b-form-checkbox name="check-button" switch v-model="data.item.switch" @change="getSwitched(data.item.id, !data.item.switch)">
                        <template v-if="data.item.switch">
                            {{ switchOn }}
                        </template>
                        <template v-else>
                            {{ switchOff }}
                        </template>
                    </b-form-checkbox>
                </div>
            </template>

			<template v-slot:cell()="data">
				<DefaultCell :item="data" />
			</template>

            <!-- Gestion des slots customs - appliquer des paramètres customs sur une colonne de la config de table -->
            <template
                v-for="(customConf, col_name) in rowToEditSlots"
                v-slot:[col_name]="data"
            >
                <!-- Extern slot only -->
                <div
                    v-if="customConf.externSlot"
                    :key="col_name"
                >
                    <!-- Si on souhaite customiser des slots en dehors du customtable -->
                    <slot :name="`custom-slot-${col_name}`" v-bind:data="data.item"></slot>
                </div>
				<ItemCell
                    v-else
					:key="col_name"
					:data="data"
					:customConf="customConf"
					:colName="col_name"
					:previewColClass="previewColClass"
					:autoConstructUrl="autoConstructUrl"
				/>
            </template>

            <template
                v-for="(customConf, col_name_bis) in slotTypes"
                v-slot:[col_name_bis]="data"
            >
                <!-- columns_type  monnaie -->
                <div :key="col_name_bis" v-if="customConf.columnType == 'monnaie'">
                    <PriceFormat
                        :pathArr="data.item"
                        :price="data.item[[customConf.cell]]"
                        :currency="customConf.currency"
                        :symbol="true"
                    />
                </div>
                <div :key="col_name_bis" v-if="customConf.columnType == 'number'">
                    <NumberFormat
                        :number="data.item[[customConf.cell]]"
                    />
                </div>
            </template>

			<!-- row details button -->
			<template v-slot:cell(show_details)="row">
				<RowDetailsButton :row="row" :columns="columns" />
			</template>

			<!-- row details content -->
			<template v-slot:row-details="row">
				<RowDetails :row="row" :columns="columns" />
			</template>
        </b-table>
    </div>
</template>

<script type="text/javascript">
import Table from '@/mixins/Table'

export default {
    name: 'CustomSubTable',
    mixins: [Table],
    props: {
        items: { type: Array, default : () => ([]) },
        fields: { type: Array, default : () => ([]) },
        columns: { type: Array, default : () => ([]) },
        group_by: null, // valeur par laquelle on doit filtrer ce tableau de groupe
        group_val: null,
        select_mode: null,
        per_page: null,
        current_page: null,
        busy: {
            type: Boolean,
            default: () => (false)
        },
		row_clicked: null,
		rowClass: {
			type: Function,
			default: () => (() => (null))
		},
        tbody_tr_class: null,
        sort_by: null,
        sort_desc: null,
        tbody_transition_props: null,
        primary_key: null,
        empty_text: null,
        has_checkboxes: {
            type: Boolean,
            default: () => (false)
        },
        head_colspan: { type: Number, default: 1}, /* Colspan pour la checkbox du group (head du sous tableau) */
        autoConstructUrl: { type: Boolean, default: false},
        rowToEditSlots: null,
        slotTypes: null,
        previewColClass: null,
        callback_switch: { type: Function, default: () => {} }, 
        switch: {type: Boolean, default: false},
        switchOn: String,
        switchOff: String,
        groupByCustom: {type: Object, default: () => {}}, /* Tableau d'objet avec en clé une colonne et en valeur une méthode appelée pour le formattage de la donnée dans le groupby */
    },
    data() {
        return {
            local_busy: true,
            local_items: [],
            switch_header: false,
        }
    },
    mounted() {
        this.init_component()
    },
    methods: {
        init_component() {
            const [, ...items_switch] = this.items
            this.switch_header = items_switch.every(val => val.switch)
        },
        active_head_line() {
            this.row_clicked(this.computed_head)
        },
        getSwitched(id, val) {
            this.callback_switch(id, val)
		},
        formatHeader(header) {
            if(this.groupByCustom !== undefined && Object.prototype.hasOwnProperty.call(this.groupByCustom, this.group_by)) {
                return this.groupByCustom[this.group_by](header)
            }
            return header
        }
    },
    computed: {
        computed_fields() {
            if(!this.fields) return []

            let local_fields = []

            this.fields.forEach(field => {
                let local_field = this.deppCloneObj(field)
                local_field.formatter = field.formatter
                local_fields.push(local_field)
            })

            for(let i in local_fields) {
                local_fields[i].label = ''
            }

            return local_fields
        },
        computed_lines() {
            return this.items.filter(item => !item['[group_by]'])
        },
        computed_head() {
            return this.items.filter(item => item['[group_by]'] !== null)[0] ?? {isActive: false}
        },
        computed_colspan() {
            return this.switch ? this.head_colspan - 2 : this.head_colspan - 1
        },
        computed_switch() {
            return this.switch
        }
	},
	components: {
        PriceFormat: () => import('GroomyRoot/components/Format/PriceFormat'),
        NumberFormat: () => import('GroomyRoot/components/Format/NumberFormat'),
		DefaultCell: () => import('GroomyRoot/components/Table/DefaultCell'),
		ItemCell: () => import('GroomyRoot/components/Table/ItemCell'),
		RowDetailsButton: () => import('GroomyRoot/components/Table/RowDetailsButton'),
		RowDetails: () => import('GroomyRoot/components/Table/RowDetails'),
	}
}
</script>
